<template>
  <Layout>
    <BaseThemeComponent
      v-if="showLocations"
      name="SectionLocations"
      title="Pick a Location to View the Menu"
    />
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'

import Layout from '@utils/get-layout'

export default {
  page: {
    title: 'Locations',
  },
  components: {
    Layout,
  },
  data() {
    return {
      showLocations: false,
    }
  },
  computed: {
    ...mapGetters('site', ['getLocationSummary']),
  },
  mounted() {
    const locations = this.getLocationSummary
    // If the site has only one location
    if (locations.length === 1) {
      // Redirect to the only location
      this.$router.push({ name: 'singleLocation', params: { locSlug: locations[0].slug } })
    } else {
      this.showLocations = true
    }
  },
  methods: {},
}
</script>
